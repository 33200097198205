.role(@bg-color) {
	background-color: @bg-color;
}

.super_admin{
	.role(@color-red);
}

.admin{
	.role(@color-blue);
}

.user{
	.role(@color-grey);
}

.atc {
    .role(@color-brown);
}

.commercial {
    .role(@color-orange);
}

.client {
    .role(@color-green);
}

.operator {
    .role(@color-yellow);
}

.manufacturer {
    .role(@color-purple);
}
