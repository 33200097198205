/*------------------------------------------------------------------
  [Pricing tables]
*/

//Pricing tables container
.pricing-tables{
  margin: @pricing-tables-margin;

  > div{
    padding-left: @pricing-table-horizontal-space;
    padding-right: @pricing-table-horizontal-space;
  }
}

//Individual element
.pricing-table{
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.04);
  border-radius: @pricing-table-border-radius;
  border-top: 3px solid darken(@light-color, 21%);
  padding: @pricing-table-padding;
  background: @light-color;
  text-align: center;
  margin: @pricing-table-margin;

  &-image{
    margin-bottom: 15px;

    svg{

      path{
        fill: darken(@light-color, 35%);
      }
    }
  }

  &-title{
    .font-size-large(5px);
    font-weight: @font-weight-light;
  }

  &-price{
    padding: 5px 0 20px;

    .currency{
      vertical-align: top;
    }

    .value{
      font-size: @pricing-table-value-size;
      line-height: @pricing-table-price-line-height;
    }

    .frecuency{
      font-size: @pricing-table-frecuency-size;
      font-weight: @font-weight-light;
    }
  }

  &-features{
    margin: 0 0 25px;
    padding: 0;
    list-style: none;

    > li{
      line-height: 25px;
      .font-size(1px);
      font-weight: @font-weight-light;
      line-height: @pricing-table-fatures-line-height;
    }
  }

  .btn{
    line-height: @pricing-table-button-height;
    min-width: @pricing-table-button-min-width;
  }
}

//Styles
.pricing-table-primary{
  border-color: @primary-color;

  .pricing-table-image{

    svg{

      path{
        fill: @primary-color;
      }
    }
  }

  &.pricing-table-color{
    background-color: @primary-color-text;
    border-color: @primary-color-text;

    .panel-divider{
      border-top-color: darken(@primary-color-text, 7%);
    }
  }
}

.pricing-table-warning{
  border-color: @warning-color;

  .pricing-table-image{

    svg{

      path{
        fill: @warning-color;
      }
    }
  }

  &.pricing-table-color{
    background-color: @warning-color-text;
    border-color: @warning-color-text;

    .panel-divider{
      border-top-color: darken(@warning-color-text, 7%);
    }
  }
}

.pricing-table-success{
  border-color: @success-color;

  .pricing-table-image{

    svg{

      path{
        fill: @success-color;
      }
    }
  }

  &.pricing-table-color{
    background-color: @success-color-text;
    border-color: @success-color-text;

    .panel-divider{
      border-top-color: darken(@success-color-text, 7%);
    }
  }
}

.pricing-table-danger{
  border-color: @danger-color;

  .pricing-table-image{

    svg{

      path{
        fill: @danger-color;
      }
    }
  }

  &.pricing-table-color{
    background-color: @danger-color-text;
    border-color: @danger-color-text;

    .panel-divider{
      border-top-color: darken(@danger-color-text, 7%);
    }
  }
}

//Colored
.pricing-table-color{
  color: @light-color;

  .pricing-table-image{

    svg{

      path{
        fill: @light-color;
      }
    }
  }

  .panel-divider{
    border-top-color: @light-color;
  }
}
