/*------------------------------------------------------------------
[General elements style]
*/

html{
  height:100%;
  overflow-x: hidden;
}

body {
  padding: 0;
  background-color: @body-background;
  height: 100%;
  font-size: 13px;
  font-family: @roboto;
}

a{

  &:hover, &:focus{
    text-decoration: none;
    outline: none;
  }
}

p{
  font-size: @paragraph-font-size;
  line-height: @paragraph-line-height;
}

//Fix for plugins like charts

canvas{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

label.required:after {
  color: red;
  content: " * ";
}

legend{
  font-size: 18px;
  padding-bottom: 5px;
}

.d-inline-block {
  display: inline-block;;
}

.badge-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.class-date-without-time {
  font-size: 11px;
}

button.action-multiple {
  border: none;
  background: white;
  padding: 8px 20px;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: #f5f5f5;
  }
}