/*------------------------------------------------------------------
  [User info list widget]
*/
.user-info-list{

  table{
    width: 100%;
    table-layout: fixed;

    tr{

      td{
        padding: 12px 0;

        &.icon{
          width: 33px;

          > span{
            .font-size-large(5px);
            vertical-align: middle;
          }
        }

        &.item{
          width: 34%;
        }
      }
    }
  }
}
