//Chat Tab
.tab-chat{
  white-space: nowrap;
  font-size: 0;//This is used to remove undesired "white space" when using inline-blocks
  width: @right-sidebar-width;

  h2{
    margin: 30px 20px 15px;
  }

  .contact-list{
    font-size: 12px;

    .user{

      a{
        display: block;
        padding: 11px 25px;
        overflow: hidden;

        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          float: left;
        }

        .user-data, .user-data2{
          padding-left: 40px;
          line-height: 16px;

          .status{
            background-color: @status-online;
            border-radius: 50%;
            float: right;
            width: 7px;
            height: 7px;
            position: relative;
            top: 5px;

            &.offline{
              background-color: @status-offline;
            }

            &.away{
              background-color: @status-away;
            }
          }

          .name{
            color: @body-color;
            font-weight: 500;
            font-size: 13px;
          }

          .message{
            display: block;
            color: darken(@right-sidebar-low-contrast-color, 10%);
            font-size: 13px;
          }
        }

        .user-data2{
          margin-top: 7px;
        }

        &:hover{
          background-color: darken(@right-sidebar-background, 2%);
        }
      }
    }

    &-recent{
      margin-bottom: 30px;
    }
  }

  .chat-contacts, .chat-window{
    height: 100%;
    width: 100%;
    display: table;
    table-layout: fixed;
    position: absolute;
    left: 0;
    transition: left 0.3s ease;
  }

  .chat-contacts{

    .chat-sections{
      height: 100%;
      display: table-row;

      .be-scroller{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .content{
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
      }
    }

    .search{
      position: relative;
    }
  }

  .chat-window{
    height: 100%;
    left: @right-sidebar-width;
    display: table;
    table-layout: fixed;

    .title{
      padding: 20px 10px;
      padding-right: 20px;
      overflow: hidden;

      .icon{
        font-size: 27px;
        width: 27px;
        text-align: center;
        color: @right-sidebar-low-contrast-color;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
      }

      .user{
        display: inline-block;
        float: right;
        text-align: right;
        line-height: 13px;

        img{
          height: 35px;
          width: 35x;
          border-radius: 50%;
          float: right;
          margin-left: 10px;
        }

        h2{
          display: inline-block;
          margin: 0;
          padding: 0;
          border: 0;
        }

        span{
          display: block;
          font-size: 12px;
          color: darken(@right-sidebar-low-contrast-color, 10%);
        }
      }
    }

    .chat-messages{
      height: 100%;
      display: table-row;
      font-size: 13px;

      .be-scroller{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: none;
      }

      .content{
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
      }

      ul{
        outline: none;
        margin: 0;
        padding: 0;

        > li{
          display: block;
          list-style: none;
          padding: 9px 16px;

          .msg{
            position: relative;
            display: inline-block;
            background-color: lighten(@primary-color, 2%);
            color: @light-color;
            font-size: 13px;
            padding: 6px 8px;
            border-radius: 3px;
            white-space: normal;
            text-align: left;
            margin-left: 40px;

            &:after{
              content: "";
              position: absolute;
              right: -5px;
              top: 7px;
              border-bottom: 6px solid transparent;
              border-left: 6px solid lighten(@primary-color, 2%);
              border-top: 6px solid transparent;
            }
          }

          &.friend{

            .msg{
              background-color: darken(@light-color, 6%);
              color: lighten(@dark-color, 11%);
              margin-left: 0;
              margin-right: 40px;

              &:after{
                right: auto;
                left: -5px;
                border-left-width: 0;
                border-right: 5px solid darken(@light-color, 6%);
              }
            }
          }

          &.self{
            text-align: right;
          }
        }
      }
    }

    .chat-input{
      position: relative;

      .input-wrapper{
        border-top: 1px solid darken(@right-sidebar-background, 3%);

        > input{
          border: 0;
          background-color: @right-sidebar-background;
          color: @right-sidebar-low-contrast-color;
          width: 100%;
          font-size: 13px;
          font-weight: 300;
          padding: 15px 20px;
          padding-left: 42px;
          padding-right: 40px;
          outline: none;
        }

        > span{
          position: absolute;
          color: @right-sidebar-icons-color;
          right: 15px;
          top: 50%;
          font-size: 20px;
          margin-top: -10px;
          cursor: pointer;

          &:hover{
            color: lighten(@right-sidebar-icons-color, 15%);
          }
        }

        .photo{
          left: 15px;
          font-size: 22px;
          top: 50%;
          right: auto;
        }
      }
    }

  }

  &.chat-opened{

    .chat-contacts{
      left: -@right-sidebar-width;
    }

    .chat-window{
      left: 0;
    }
  }
}
