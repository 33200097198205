/*------------------------------------------------------------------
  [Elements]
*/
/*------------------------------------------------------------------
  [Widgets]
*/
.widget{
  background-color: @light-color;
  padding: 20px;
  margin-bottom: @widget-margin-bottom;
  border-radius: @panel-border-radius;

  &.widget-fullwidth{
    padding: 0;

    .widget-head{
      padding: 20px @widget-horizontal-padding 10px;
      margin-bottom: 0;

      @media @small-phone{
        padding-left: @widget-horizontal-padding-small;
        padding-left: @widget-horizontal-padding-small;
      }
    }

    .widget-chart-container{

      .counter{
        left: @widget-horizontal-padding;
      }
    }

    .chart-table{

      table{
        margin-bottom: 0;
      }
    }
  }

  &.widget-small{
    margin-bottom: 20px;

    &.widget-fullwidth{

      > .widget-head{
        padding: 20px 15px 10px;
      }
    }
  }
}

//Widget head
.widget-head{
  margin-bottom: 20px;
  display: block;
  border-radius: @panel-border-radius @panel-border-radius 0 0;
  .clearfix();

  .title{
    display: block;
    font-size: @widget-heading-font-size;
    font-weight: @widget-heading-font-weight;
  }

  .description{
    display: block;
    color: hsl(0, 0%, 62%);
    line-height: 22px;
    font-weight: @font-weight-light;
  }

  //Icon tools
  .tools{
    float: right;
    margin-left: 10px;

    .icon{
      display: inline-block;
      font-size: @panel-heading-icon-size;
      line-height: @panel-heading-icon-line-height;
      vertical-align: middle;
      cursor: pointer;
      color: @panel-heading-icon-color;
      min-width: @panel-heading-icon-size;
      text-align: center;

      & + .icon{
        margin-left: @panel-heading-icon-separation;
      }

      &:hover{
        color: @panel-heading-icon-hover;
      }
    }

    .value{
      font-size: 24px;
      line-height: 22px;
    }

    .dropdown{
      display: inline-block;
    }

    //Open dropdowns from right to left
    .dropdown-menu{
      right: 0;
      left: auto;
    }
  }

  //Button toolbar
  .button-toolbar{
    float: right;

    .btn-group{

      @media @phone{
        margin-bottom: 5px;
      }

      & + * {
        margin-left: 7px;
      }
    }

    //Open dropdowns from right to left
    .dropdown-menu{
      right: 0;
      left: auto;
    }
  }
}

//Widget chart container
.widget-chart-container{
  position: relative;

  .counter{
    min-height: auto;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;

    &-inline{
      position: relative;
      display: inline-block;
    }

    .value{
      .font-size-large(9px);
    }

    .desc{
      text-transform: uppercase;
      font-size: 11px;
      color: lighten(@text-color, 36.5%);
    }
  }

  .counter-big{

    .value{
      display: block;
      .font-size-large(12px);
      line-height: @font-size-large + 12px;
      font-weight: @font-weight-light;
      margin-bottom: 5px;
    }
  }

  .indicator{

    &-equal{

      .icon{
        color: @primary-color;
      }
    }

    &-positive{

      .icon{
        color: @success-color;
      }
    }

    &-negative{

      .icon{
        color: @danger-color;
      }
    }

    .icon{
      font-size: 25px;
      margin-right: 7px;
      line-height: 24px;
      vertical-align: middle;
    }

    .number{
      .font-size-large(5px);
      vertical-align: middle;
    }
  }
}

//Chart information section
.widget-chart-info{
  .clearfix();

  @media @phone{

    & + .widget-counter-group{
      margin-top: 15px;
    }
  }

  .widget-fullwidth &{
    padding: 9px @widget-horizontal-padding 0;
  }
}

//Widget counter group
.widget-counter-group{
  padding: 0 @widget-horizontal-padding 10px;

  &-right{
    text-align: right;
  }

  .counter{
    position: static;
    display: inline-block;

    & + .counter{
      margin-left: 20px;
    }
  }
}
