/*------------------------------------------------------------------
  [Modals]
*/

.modal-content{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: @light-color;
  color: @text-color;
  border: none;
}

//Modal Header
.modal-header{
  border: 0;
  padding: 19px;

  h1, h2, h3, h4, h5, h6{
    display: block;
    margin: 0;
  }

  .close{
    font-size: 20px;
    opacity: 0.4;
    text-shadow: none;

    &:hover{
      opacity: 0.8;
    }
  }
}

//Modal Body
.modal-body{
  padding: 10px 20px 20px;

  //Remove margin bottom from last row form group
  .row:last-child{

    .form-group{
      margin-bottom: 0;
    }
  }
}

//Modal Main Icon
.modal-main-icon{
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: normal;
  font-size: 50px;
}

//Modal Footer
.modal-footer{
  padding: 20px;
  border: none;
}

//Full Color Alerts
.modal-full-color{

  .modal-header{
    padding: 19px;
  }

  .modal-content{
    color: @light-color;
    background-color: transparent;

    .close{
      color: @light-color;
      line-height: 40px;
      opacity: 0.6;

      &:hover{
        opacity: 1;
      }
    }

    .modal-footer{
      border: none;
    }
  }

  .btn-default{
    border-width: 2px;
    border-color: transparent;
  }

  //Transparent style for color buttons
  .btn-primary,
  .btn-success,
  .btn-warning,
  .btn-danger{
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 2px;

    &:focus{
      background-color: transparent;
      border-color: rgba(255, 255, 255, 0.4);
    }

    &:hover,.hover{
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.8);
    }

    &:active{
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.8);
    }
  }
}

//Modal Full Color set
.modal-full-color-success{
  .modal-full-color(@success-color-text);
}

.modal-full-color-primary{
  .modal-full-color(@primary-color-text);
}

.modal-full-color-warning{
  .modal-full-color(@warning-color-text);
}

.modal-full-color-danger{
  .modal-full-color(@danger-color-text);
}

.modal-full-color-dark{
  .modal-full-color(@dark-color);
}


//Bootstrap Modals
.full-width{
  width: 90%;
  max-width:none;
}

//Colored Header Modals
.colored-header{

  .modal-content{

    .modal-header{
      background-color: darken(@light-color, 10%);
      color: @light-color;
      padding: 30px 20px;
      .modal-title{
        font-size: 20px;
      }

      .close{
        color: rgba(0, 0, 0, 0.7);
        margin-top: 2px;
      }
    }
    .modal-body{
      padding: 35px 20px 20px;
      background-color: @light-color;
      color: @text-color;
    }
    .modal-footer{
      background-color: @light-color;
    }
  }
}

//Colored header color set

.colored-header-success{
  .colored-header(@success-color-text);
}
.colored-header-primary{
  .colored-header(@primary-color);

  .modal-content{
    .modal-header{
      .close {
        color: #fff;
      }
    }
  }
}
.colored-header-warning{
  .colored-header(@warning-color-text);
}
.colored-header-danger{
  .colored-header(@danger-color-text);
}
.colored-header-dark{
  .colored-header(@dark-color);
}

//Responsive Design
@media @phone{

  .modal-dialog{
    min-width: 100%;
    padding: 0 15px;
  }
}

.be-modal-open{
  overflow-x: visible;
}
