/*------------------------------------------------------------------
  [Error pages]
*/
.be-error{

  .error-container{
    text-align: center;
    margin-top: 100px;

    @media @phone{
      margin-top: 50px;
    }
  }

  .error-number{
    display: block;
    font-size: 150px;
    font-weight: @font-weight-bold;
    line-height: 240px;
    margin-bottom: 30px;
    max-height: 240px;

    &:after {
      padding-top: 0.25%;
      display: block;
      content: '';
    }

    @media @phone{
      padding: 20px 0;
      font-size: 90px;
      margin-bottom: 10px;
      line-height: 100px;
    }
  }

  .error-description{
    .font-size-large(15px);
    font-weight: @font-weight-light;
    line-height: 32px;
    margin-bottom: 30px;

    @media @phone{
      .font-size-large(4px);
    }
  }

  .error-goback-text{
    .font-size-large(7px);
    font-weight: @font-weight-light;
    margin-bottom: 30px;
    margin-top: 15px;

    @media @phone{
      font-size: @font-size-large;
    }
  }

  .error-goback-button{
    margin-bottom: 30px;


    .btn{
      min-width: 153px;
      text-align: center;
    }
  }
}

/*------------------------------------------------------------------
  [Page 404]
*/
.be-error-404{

  .error-container{

    .error-number{
      @media @small-phone{
        padding: 0;
        font-size: 70px;
      }

      @media (max-width: 340px){
        font-size: 50px;
      }

      @media (max-width: 295px){
        font-size: 40px;
      }
    }
  }
}
