.history-notification {
  font-size: 12px;
}

.red{
  color: red;
  font-size: 14px;
}

.green {
  color: green;
  font-size: 14px;
}

.btn-sendinblue-status {
  border-radius: 14px;
  font-size: 12px;
  padding: 5px;
  font-weight: 400;
  background-color: #66b2dc;
}

.bg-sendinblue-bounces {
  background-color: #e6735c !important;
}

.bg-sendinblue-hardBounces {
  background-color: #e6735c !important;
}

.bg-sendinblue-softBounces {
  background-color: #ea8b6f !important;
}

.bg-sendinblue-delivered {
  background-color: #4388ae !important;
}

.bg-sendinblue-spam {
  background-color: #cd7867 !important;
}

.bg-sendinblue-requests {
  background-color: #98a0ab !important;
}

.bg-sendinblue-opened {
  background-color: #66b2dc !important;
}

.bg-sendinblue-clicks {
  background-color: #9cc980 !important;
}

.bg-sendinblue-invalid {
  background-color: #cd7867 !important;
}

.bg-sendinblue-deferred {
  background-color: #efa381 !important;
}

.bg-sendinblue-blocked {
  background-color: #f7d3a6 !important;
}

.bg-sendinblue-unsubscribed {
  background-color: #f7d3a6 !important;
}
