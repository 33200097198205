button.multiselect {
  text-align: left;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-right: 24px;

  b.caret {
    position: absolute;
    top: 14px;
    right: 8px;
  }
}

.multiselect-container {
  li.active a {
    background-color: #ccc;

    &:hover {
      background-color: #aaa;
    }
  }

  li > a {
    white-space: normal;

    &:hover {
      background-color: #aaa;
    }

    .checkbox, .radio {
      padding-top: 0;
      min-height: 0;
    }
  }
}

@media (min-width: @screen-xs-min) {
  .multiselect-container li>a {
    white-space: nowrap;
  }
}
