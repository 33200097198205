table.datatable {
  margin: 0 !important;
  border-top: 1px solid @input-border;
}

div.datatable-wrapper {
  div.datatable-info {
    color: lighten(@text-color, 13%);
    padding-top: 9px;
  }

  div.datatable-paginate {
    text-align: right;

    ul.pagination {
      margin: 0;
    }
  }

  div.datatable-tools {
    padding: 10px 35px;
  }
}

.datatable-header {
  border-bottom: 1px solid @input-border;
  margin: 2px 0 0;
  padding: 15px 10px 22px;
}

.datatable-footer {
  margin: 0;
  padding: 15px 3px 13px;
  background-color: darken(@light-color, 3%);
  border-top: 1px solid @input-border;
}
