/*------------------------------------------------------------------
  [Dividers inside panels]
*/
.panel-divider{
  margin: @divider-margin 0;
  border-top: @divider-border;

  &-lg{
    margin: @divider-lg-margin 0;
  }

  &-xl{
    margin: @divider-xl-margin 0;
  }
}
