/*------------------------------------------------------------------
  [User current progress widget]
*/
.user-progress{
  margin-bottom: 15px;

  .title{
    display: block;
    margin-bottom: 10px;
  }

  .value{
    display: block;
    text-align: right;
    .font-size-large(3px);
    padding-top: 18px;
  }

  .progress{
    height: @user-progress-height;
    border-radius: ceil(@user-progress-height / 2);
    margin-bottom: 0;

    &-bar{
      border-radius: ceil(@user-progress-height / 2);
    }
  }

  &-small{
    margin-bottom: 20px;

    .title{
      display: inline-block;
    }

    .progress{
      margin-top: 5px;
      height: 5px;
    }
  }
}
