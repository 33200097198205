.autocomplete-suggestions {
  border: 1px solid @input-border;
  background: @input-bg;
  overflow: auto;

  .autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;

    &.autocomplete-selected {
      background: darken(@input-bg, 10%);
    }
  }

  strong {
    font-weight: 700;
    color: @primary-color;
  }

  .autocomplete-group {
    padding: 2px 5px;

    strong {
      display: block;
      border-bottom: 1px solid #000;
    }
  }
}

.autocomplete-list {
  margin-top: 20px;
  padding-left: 20px;
}
