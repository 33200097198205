//Right sidebar animation
//Without Hardware Acceleration
.right-sidebar-animation(@ha_animation) when (@ha_animation = false){

  .be-right-sidebar{
    right: 0;
  }
}

//With Hardware Acceleration
.right-sidebar-animation(@ha_animation) when (@ha_animation = true){

  @media @phone{

    .be-wrapper{
      overflow: hidden;
    }

    .be-right-sidebar{
      transform: translate3d(0, 0, 0);
    }
  }
}
