/*------------------------------------------------------------------
  [Label]
*/
.label{
  border-radius: 2px;
  padding: 3px 7px;
  font-size: @font-size-small - 1px;
  font-weight: @font-weight-bold;
}

.label-default{
  border: 1px solid darken(@light-color, 15%);
  color: @text-color;
}

.label-red {
  .label-color(@color-red);
}
.label-pink {
  .label-color(@color-pink);
}
.label-purple {
  .label-color(@color-purple);
}
.label-deep-purple {
  .label-color(@color-deep-purple);
}
.label-indigo {
  .label-color(@color-indigo);
}
.label-blue {
  .label-color(@color-blue);
}
.label-light-blue {
  .label-color(@color-light-blue);
}
.label-cyan {
  .label-color(@color-cyan);
}
.label-teal {
  .label-color(@color-teal);
}
.label-green {
  .label-color(@color-green);
}
.label-light-green {
  .label-color(@color-light-green);
}
.label-lime {
  .label-color(@color-lime);
}
.label-yellow {
  .label-color(@color-yellow);
}
.label-amber {
  .label-color(@color-amber);
}
.label-orange {
  .label-color(@color-orange);
}
.label-deep-orange {
  .label-color(@color-deep-orange);
}
.label-brown {
  .label-color(@color-brown);
}
.label-grey {
  .label-color(@color-grey);
}
.label-blue-grey {
  .label-color(@color-blue-grey);
}
.label-black {
  .label-color(@color-black);
}
.label-white {
  .label-color(@color-white);
}
