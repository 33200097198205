/*------------------------------------------------------------------
  [Timeline widget]
*/
.timeline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 2px;
    background: @primary-color;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .timeline-block {
    position: relative;
    margin-bottom: 30px;

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    .timeline-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 19px;
      padding: 5px;
      border: 2px solid @primary-color;
      text-align: center;
      vertical-align: middle;

    }

    .timeline-content {
      position: relative;
      margin-left: 60px;
      padding-top: 10px;

      .timeline-date {
        text-transform: uppercase;
        color: lighten(@text-color, 20%);
        margin-bottom: 8px;
      }

      .timeline-title {
        font-weight: @font-weight-medium;
      }

      .timeline-description {

      }
    }
  }
}

// Ne pas bouger cette règle, magie toussa
.timeline-icon {
  background-color: #000;
  color: #fff;
}
