/*------------------------------------------------------------------
  [Pagination]
*/
.pagination{
  padding-left: 0;
  margin: @line-height-computed 0;
  border-radius: @border-radius-base;

  > li {
    > a,
    > span {
      font-size: 14px;
      line-height: 14px;
      font-weight: @font-weight-regular;
      padding: @padding-base-vertical @padding-base-horizontal;
      color: @pagination-color;
      background-color: @pagination-bg;
      border: 1px solid @pagination-border;
      border-radius: 2px;
      margin-left: 4px;

      span{
        line-height: 12px;
      }
    }
    &:first-child {
      > a,
      > span {
        border-radius: 2px;
      }
    }
    &:last-child {
      > a,
      > span {
        border-radius: 2px;
      }
    }
  }
}

.pagination-rounded{
  > li{

    > a, > span{
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 14px;
    }

    &:first-child {
      > a,
      > span {
        border-radius: 50%;
      }
    }

    &:last-child {
      > a,
      > span {
        border-radius: 50%;
      }
    }
  }
}
