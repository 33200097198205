.count-badge {
    position: absolute;
    right: 20px;
    top: 10px;
}

.comment-type-font {
    font-size: 11px;
    padding: 2%;
}
