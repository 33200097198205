/*------------------------------------------------------------------
  [Main wrapper]
*/
.be-wrapper{
  min-height: 100%;
  overflow-x: hidden;
  padding-top: @top-header-height + 1px;

  @media @tablet{
    padding-top: 121px;
  }

}

