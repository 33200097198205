/*------------------------------------------------------------------
  [Page head]
*/

.page-head {
  padding: 10px @content-padding 10px;
  position: relative;
  background: @page-head-bg;
  border-bottom: 1px solid darken(@light-color, 20%);

  &-title{
    display: block;
    margin-bottom: 5px;
    margin-top: 6px;
    font-weight: @font-weight-light;
    letter-spacing: -1px;
    .font-size-large(13px);
    color: #FFFFFF;
    line-height: 36px;
  }

  &-description{
    display: block;
    color: hsl(0, 0%, 43%);
    .font-size(1px);
    font-weight: @font-weight-light;
  }

  .breadcrumb{
    background-color: transparent;
    padding: 3px 0;
    margin-bottom: 7px;

    > li{
      .font-size(1px);
      font-weight: @font-weight-light;

      & + li:before{
        padding: 0 2px;
        color: hsl(0, 0%, 64%);
      }

      > a{
        color: hsl(0,0%,37%);

        &:hover{
          color: hsl(0, 0%, 19%);
        }
      }
    }

    > .active{
      color: hsl(0, 0%, 48%);
    }
  }

  .page-head-actions {
    text-align: right;
    margin-top: 8px;

    .btn-default{
      border-color: #fff;
      color:#ffffff;
      background-color: transparent;
      border-radius: 5px;
    }
  }

  @media @phone{
    padding: 18px @content-padding-phone 0;
    display: none;

    &-title{
      .font-size-large(9px);
      line-height: 28px;
    }
  }
}
