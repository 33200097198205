//Full colored modal
.dropdown-color(@color) {
  border-color: transparent;
  background-color: @color;

  > li {

    //First level
    > a {
      color: @light-color;

      &:focus, &:active {
        color: @light-color;
      }

      &:hover {
        background-color: darken(@color, 5%);
        color: @light-color;
      }

      .icon {
        color: @light-color;
      }
    }

    .dropdown-tools {

      .btn-group {

        .btn {
          background-color: transparent;
          color: @light-color;
          border-color: lighten(@color, 18%);
          border-width: 2px;

          &:hover {
            border-color: @light-color;
            box-shadow: none;
            background-color: rgba(255, 255, 255, 0.1);

            & + .btn {
              border-left-color: transparent;
            }
          }

          &:active,
          &.active {
            background-color: @light-color;
            color: @color;
            border-color: transparent;
            border-width: 2px;
            box-shadow: @btn-box-shadow-base;
          }

          &:focus {
            box-shadow: @btn-box-shadow-base;
          }
        }
      }
    }
  }

  //Divider Line
  .divider {
    background-color: darken(@color, 5%);
  }
}
