/*------------------------------------------------------------------
  [Timeline compact widget]
*/
.timeline-compact{
  padding: 0;
  margin: 5px 0 15px;
  list-style: none;
  position: relative;

  &:before{
    content: "";
    position: absolute;
    left: 7px;
    top: 2px;
    height: 100%;
    width: 2px;
    background-color: @primary-color;
  }

  &:after{
    content: "";
    position: absolute;
    left: 4px;
    bottom: -2px;
    height: 8px;
    width: 8px;
    background-color: @primary-color;
    border-radius: 50%;
  }

  > li{
    display: block;
    position: relative;
    padding-left: 30px;
    padding-bottom: 27px;

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      height: 16px;
      width: 16px;
      background-color: @light-color;
      border-radius: 50%;
      border: 1px solid @primary-color;
    }

    &.latest{

      &:before{
        background-color: @primary-color;
      }
    }
  }

  &-date{
    text-transform: uppercase;
    color: lighten(@text-color, 20%);
    margin-bottom: 8px;
  }

  &-title{
    font-weight: @font-weight-medium;
  }
}
