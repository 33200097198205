.panel-heading{
  font-size: 14px;
}

.nav-wizard > li > a{
  background-color: #fff;
}

.nav-wizard > li:not(:first-child) > a:before {
  border-left: 20px solid #eee;
}

.nav-wizard > li:not(:last-child) > a:after {
  border-left: 20px solid #fff;
}

.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}

.nav-wizard > li.purple > a::after {
  border-left-color: #9c27b0;
}

.atc-form {
  padding-bottom: 5.3rem;
}

.merge-project-form {
  padding-bottom: 9rem;
}

.panel{
  padding-bottom: 3px;
}

.panel-project .nav-wizard{
  text-align: right;
}

.panel-project .nav-wizard > li{
  display: inline-block;
  float: none;
}

.panel-project .nav-wizard > li > a{
  padding:6px 15px;
}

.panel-project .nav-wizard > li:not(:first-child) > a{
  padding-left: 34px;
}

.panel-project .nav-wizard > li:not(:last-child) > a:after {
  border-top: 16px inset transparent;
  border-bottom: 16px inset transparent;
}

.panel-project .nav-wizard > li:not(:first-child) > a:before {
  border-top: 16px inset transparent;
  border-bottom: 16px inset transparent;
}

.btn-add-comment{
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-size: 24px;
  cursor:pointer;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover{
  border-bottom: 1px solid #fff;
}

.panel-body .row-user-notification{
  padding-top: 5px;
  padding-bottom: 5px;
}

.panel-body .row-user-notification:nth-child(odd){
  background-color: #f5f5f5;
}

.panel-body .row-user-notification .col-md-1,
.panel-body .row-user-notification .col-md-4,
.panel-body .row-user-notification .col-md-5{
  line-height: 24px;
  word-break: break-all;
}


.panel-body .row-user-notification .btn-group > .btn{
  line-height: 22px;
  width:36px;
}

.panel-body .row-user-notification .btn-group > .btn:first-child{
  margin-left: -1px;
}

.panel-body .row-send-notification{
  padding-top: 5px;
  padding-bottom: 5px;
}

.panel-body .row-send-notification:nth-child(odd){
  background-color: #f5f5f5;
}

.panel-body .row-send-notification .col-md-12{
  line-height: 30px;
}

.panel-body .row-send-notification .checkbox{
  margin: 0;
}

.panel-body .row-send-notification input[type="checkbox"]{
  position: relative;
  margin-right: 10px;
}

.btn-send-notification{
  margin-top: 20px;
}

.status-label{
  padding:6px 10px;
  margin-right: 10px;
  position: relative;
  top: 1px;
  border:none;
}

.project-number{
  font-weight: bold;
  margin-bottom: 5px;
}

.btn-add-comment{
  color: @chirat-blue;
}

.panel-heading .tools .btn-add-comment .icon {
  color: @chirat-blue;
  font-weight: bold;
  position: relative;
  top: -2px;
}

.label-default{
  display: inline-block;
}








.project-head{
  color:@primary-color;
  font-size:20px;
  font-weight:bold;
  border-bottom: 1px solid @primary-color;
}

.project-head h2{
  font-weight:bold;
  color:#333;
  font-size: 18px;
  margin:15px 0px 5px 0px;

  @media only screen and (max-width : 480px) {
    text-align:center;
  }
}

.project-head .name,
.project-head .number
{
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px;

  @media only screen and (max-width : 480px) {
    margin-top:10px;
    text-align:center;
  }
}

.project-element span{
  color:#777777;
}

.comment span.date,
.comment span.author{
  color:#777777;
}

.project-status{
  padding:15px;
}

.project-status-color(@color) {
  color:@color;
  background-color: lighten(@color, 35%);
}

.project-status-ABANDONED {
  .project-status-color(@color-grey);
}

.project-status-CLOSED {
  .project-status-color(@color-red);
}

.project-status-IN_PROGRESS {
  .project-status-color(@color-blue);
}

.project-status-VALIDATED {
  .project-status-color(@color-green);
}

.project-status-WAITING_FOR_FILE {
  .project-status-color(@color-orange);
}

.project-element-kind-workflow-buttons{
  @media only screen and (max-width : 480px) {
    text-align:center;
  }
}

.nav-wizard > li > a{
  background-color: #fff;
}

.nav-wizard > li:not(:first-child) > a:before {
  border-left: 20px solid #eee;
}

.nav-wizard > li:not(:last-child) > a:after {
  border-left: 20px solid #fff;
}

.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}

.panel-project > .panel-heading{
  padding: 5px 10px;
  background-color: #f7f7f7;
  margin:0;
}

.panel-title{
  padding-top: 12px;
  cursor:pointer;
  font-weight: bold;
}

.panel-title .fa{
  position: relative;
  top:-1px;
  margin-right: 5px;
}

.panel-body{
  padding:10px 25px;
}

.panel-body > .error-row{
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
.panel-body > .error-row:nth-child(odd){
  background-color: #eee;
}
*/

.panel-body > .error-row .error-line:nth-child(odd){
  background-color: #eee;
}

.panel-body .error-description{
  padding-bottom:5px;
}

.btn-reporting-validate{
  padding:5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border:2px solid #bbb;
  display:inline-block;

  @media @phone {
    width: 100%;
    margin-bottom: 10px;
  }

}

.btn-reporting-validate:hover{
  border:2px solid #333;
  background-color: #333;
  color: #fff !important;
}

.btn-reporting-validate:hover span{
  color: #fff !important;
}

.btn-add-comment {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-size: 24px;
  cursor:pointer;
}

.text-element-info {
  font-size: 16px;
  font-weight: bold;
}

.text-element-ok{
  color:@color-green;
}

.text-element-warning {
  color:@color-orange;
}

.btn-validate-file{
  padding:5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border:2px solid #bbb;
  color:@color-green;;
  display:inline-block;

  @media @phone{
    width: 100%;
    margin-bottom: 10px;
    background-color: @color-green;
    border:2px solid @color-green;
    color:#fff;
  }

}

.btn-validate-file:hover{
  padding:5px 10px;
  border-radius: 5px;
  background-color: @color-green;
  border:2px solid @color-green;
  color:#fff;
  display:inline-block;
}

.download-pdf-control{
  color:#000;
  font-size: 16px;
  font-weight: bold;
}

.project-head-nav{
  padding:0;
  margin:10px 0 0 0;
  font-size:0;
  border-bottom:4px solid @primary-color;
  background-color: @primary-color;
}

.project-head-nav li{
  padding:10px 10px 5px 10px;
  display:inline-block;
  text-align:center;
  width:50%;
  vertical-align: top;
  border-top: 1px solid #C7C7CC;
  border-left: 1px solid #C7C7CC;
  border-right: 1px solid #C7C7CC;
  background-color:#fff;
}
.project-head-nav li .correction{
  color: #d12f19;
}

.project-head-nav li.active{
  background-color:@primary-color;
  border: none;
  color: #fff;
  margin-top: -1%;
  z-index: 99;
}

.project-head-nav li a{
  font-size:14px;
}

.project-head-nav li a span{
  color:#333;
  display: inline-block;
  vertical-align: top;
  padding-top: 5px;
}

.project-head-nav li a i{
  display:inline-block;
  margin-right: 10px;
}

.project-head-nav li.active a,
.project-head-nav li.active a span{
  color:#ffffff;
}

@media only screen and (max-width : @screen-md) {

  .project-head-nav li{
    min-height: 80px;
  }

  .project-head-nav li a span{
    display:none;
  }

  .project-head-nav li a i{
    font-size:2em;
    display:block;
    margin-bottom: 15px;
  }
}
