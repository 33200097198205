.platform-environment{
  background-color:@color-red;
  color:#ffffff;
  padding:5px;
  margin:0px;
  display:inline-block;
  position: relative;
  top: -2px;
  left:7px;
}
