@bower-path: "../../../components";

// Table of content
@import "includes/table-of-content.less";

// Bower
@import "@{bower-path}/bootstrap/dist/css/bootstrap.min.css";
@import "@{bower-path}/font-awesome/css/font-awesome.min.css";
@import "@{bower-path}/material-design-iconic-font/dist/css/material-design-iconic-font.min.css";
@import "@{bower-path}/bootstrap-fileinput/css/fileinput.min.css";
@import "@{bower-path}/bootstrap-fileinput/themes/explorer/theme.css";
// @import "@{bower-path}/material-design-iconic-font/less/material-design-iconic-font.less";
// @import "@{bower-path}/perfect-scrollbar/css/perfect-scrollbar.min.css";
// @import "@{bower-path}/bootstrap/less/bootstrap.less";
// @import "@{bower-path}/font-awesome/less/font-awesome.less";
@import "@{bower-path}/foxythemes-niftymodals/src/less/style-bootstrap.less";
@import "@{bower-path}/bootstrap-multiselect/dist/less/bootstrap-multiselect.less";
@import "@{bower-path}/bootstrap-datepicker/less/datepicker3.less";
@import "@{bower-path}/bootstrap-select/less/bootstrap-select.less";


//Config variables
@import "includes/config.less";
@import "includes/bootstrap-variables.less";

//Mixins
@import "includes/structure/sidebars-push-effect.less";
@import "includes/mixins/background-variant.less";
@import "includes/mixins/triangle.less";
@import "includes/mixins/font-size.less";

//Fonts
@import "includes/fonts/roboto.less";

// Structure
@import "includes/structure/main-wrapper.less";
@import "includes/structure/top-header.less";
@import "includes/structure/top-header/icons-nav.less";
@import "includes/structure/top-header/user-dropdown.less";
@import "includes/structure/top-header/notifications-dropdown.less";
@import "includes/structure/top-header/connections-dropdown.less";
@import "includes/structure/left-sidebar.less";
@import "includes/structure/left-sidebar/progress-widget.less";
@import "includes/structure/right-sidebar.less";
@import "includes/structure/right-sidebar/tab-chat.less";
@import "includes/structure/right-sidebar/tab-todo.less";
@import "includes/structure/right-sidebar/tab-settings.less";
@import "includes/structure/main-content.less";
@import "includes/structure/page-head.less";
@import "includes/structure/page-aside.less";
@import "includes/structure/splash-screen.less";
@import "includes/structure/scroll-top.less";

// Forms
@import "includes/forms/switch-button.less";

// Widgets
@import "includes/elements/widgets/calendar.less";
@import "includes/elements/widgets/map.less";
@import "includes/elements/widgets/tiles.less";
@import "includes/elements/widgets/user-profile-display.less";
@import "includes/elements/widgets/user-info-list.less";
@import "includes/elements/widgets/user-progress.less";
@import "includes/elements/widgets/timeline.less";
@import "includes/elements/widgets/timeline-compact.less";

// Elements
@import "includes/elements/widgets.less";
@import "includes/elements/charts.less";
@import "includes/elements/colors.less";
@import "includes/elements/grid.less";
@import "includes/elements/icons.less";
@import "includes/elements/images.less";
@import "includes/elements/loader.less";
@import "includes/elements/code.less";
@import "includes/elements/divider.less";
@import "includes/elements/pricing-tables.less";
@import "includes/elements/tables.less";
@import "includes/elements/multiselect.less";
@import "includes/elements/tree.less";
@import "includes/elements/wizard.less";
@import "includes/elements/nav.less";
@import "includes/elements/pointing_header.less";

// Specific pages style
@import "includes/pages/login.less";
@import "includes/pages/404.less";
@import "includes/pages/sign-up.less";
@import "includes/pages/forgot-password.less";
@import "includes/pages/gallery.less";
@import "includes/pages/email.less";
@import "includes/pages/project-notification.less";
@import "includes/pages/project.less";
@import "includes/pages/project-merge.less";
@import "includes/pages/dashboard.less";
@import "../../front/less/includes/pages/gabarits.less";

// Bootstrap elements
@import "includes/bootstrap/mixins/alerts.less";
@import "includes/bootstrap/mixins/buttons.less";
@import "includes/bootstrap/mixins/table-row.less";
@import "includes/bootstrap/mixins/panels.less";
@import "includes/bootstrap/mixins/modals.less";
@import "includes/bootstrap/mixins/dropdown.less";
@import "includes/bootstrap/mixins/labels.less";
@import "includes/bootstrap/mixins/badges.less";
@import "includes/bootstrap/mixins/tabs.less";
@import "includes/bootstrap/mixins/forms.less";

@import "includes/bootstrap/grid.less";
@import "includes/bootstrap/images.less";
@import "includes/bootstrap/code.less";
@import "includes/bootstrap/buttons.less";
@import "includes/bootstrap/button-group.less";
@import "includes/bootstrap/breadcrumbs.less";
@import "includes/bootstrap/panels.less";
@import "includes/bootstrap/dropdowns.less";
@import "includes/bootstrap/accordions.less";
@import "includes/bootstrap/tabs.less";
@import "includes/bootstrap/modals.less";
@import "includes/bootstrap/alerts.less";
@import "includes/bootstrap/tables.less";
@import "includes/bootstrap/badges.less";
@import "includes/bootstrap/labels.less";
@import "includes/bootstrap/popovers.less";
@import "includes/bootstrap/list-group.less";
@import "includes/bootstrap/type.less";
@import "includes/bootstrap/progress-bars.less";
@import "includes/bootstrap/pagination.less";
@import "includes/bootstrap/forms.less";
@import "includes/bootstrap/input-groups.less";
@import "includes/bootstrap/navs.less";

// Plugins
@import "includes/plugins/perfect-scrollbar.less";
// @import "includes/plugins/gritter.less";
// @import "includes/plugins/dropzone.less";
// @import "includes/plugins/wizard.less";
// @import "includes/plugins/nestable-lists.less";
// @import "includes/plugins/datetimepicker.less";
// @import "includes/plugins/select2.less";
// @import "includes/plugins/slider.less";
// @import "includes/plugins/summernote.less";
// @import "includes/plugins/bootstrap-markdown.less";
// @import "includes/plugins/parsley.less";
// @import "includes/plugins/datatables.less";
// @import "includes/plugins/vector-maps.less";
// @import "includes/plugins/full-calendar.less";
@import "includes/plugins/devbridge-autocomplete.less";

// Helper classes
@import "includes/helpers.less";

//General style
@import "includes/general.less";

// Modules
@import "includes/modules/user/roles.less";
@import "includes/modules/platform/platform.less";
