/*------------------------------------------------------------------
  [Tables]
*/
.table{

  > thead{

    > tr{

      > th{
        border-bottom-width: 2px;
        font-weight: @font-weight-bold;
      }
    }

    //Color classes
    &.primary{

      > tr{
        > th{
          color: @primary-color;
        }
      }
    }

    &.success{

      > tr{
        > th{
          color: @success-color;
        }
      }
    }
  }

  > tbody{

    > tr{
      > td{
        font-weight: @font-weight-regular;
        vertical-align: middle;
      }
    }
  }
}

.table-condensed{

  > thead{

    > tr{

      > th{
        padding-top: 10px;
        padding-bottom: 8px;
      }
    }
  }
}

.table-bordered{

  > thead{

    > tr{

      > th{
        padding: 14px 10px;
      }
    }
  }

  tbody{

    >tr{

      td{

        &:first-child{
          padding-left: 10px;
        }

        &:last-child{
          padding-right: 10px;
        }
      }
    }
  }
}

// Generate the contextual variants
.table-row-variant(primary; @primary-color-text);

.table-responsive{
  border: 0;

  tbody{

    > tr{

      > td{
        padding-top: @table-condensed-cell-padding + 4px;
        padding-bottom: @table-condensed-cell-padding + 4px;
      }
    }
  }
}

//Helpers classes
.table{

  tr{

    th, td{

      .be-checkbox{
        padding: 0;
      }

      //Actions column
      &.actions{
        text-align: center;

        .icon{
          color: darken(@light-color, 37%);
          font-size: @font-size-base + 5px;
          line-height: 18px;

          &:hover{
            color: darken(@light-color, 50%);
          }
        }
      }
    }
  }

  tbody{

    tr{

      td{

        &.user-avatar{

          img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 10px;

            @media @phone{
              display: none;
            }
          }
        }

        &.cell-detail{

          span{
            display: block;
          }

          .cell-detail-description{
            display: block;
            font-size: @font-size-base - 2px;
            color: darken(@light-color, 40%);
          }
        }

        &.user-info{

          img{
            float: left;
          }

          span{
            padding-left: 40px;
          }
        }

        &.milestone{

          span{
            display: block;
            font-size: @font-size-base - 2px;
            color: darken(@light-color, 40%);
            margin-bottom: 6px;

            &.completed{
              float: right;
            }
          }

          .progress{
            height: 6px;
            background-color: darken(@light-color, 10%);
            margin-bottom: 0;

            .progress-bar{
              border-radius: @border-radius-base;
            }
          }
        }
      }

      &.online{

        td:first-child{
          border-left: 3px solid @primary-color-text;
          padding-left: 17px;
        }
      }
    }
  }

  .number{
    text-align: right;
  }

  &.table-borderless{

    tbody{

      tr{

        td{
          border-top-width: 0;
        }
      }
    }
  }
}

//Table inside full-width widget
.panel-table{
  table{
      margin-bottom: 0;
    }

  tr{

    th, td{

      &:first-child{
        padding-left: 20px;
      }

      &:last-child{
        padding-right: 20px;
      }
    }
  }

  thead{

    tr{

      th{
        padding-top: 15px;
        padding-bottom: 10px;
      }
    }
  }
}
