
/*Breadcrumbs*/
.breadcrumb{
  background-color: @breadcrumb-bg;
  color: @breadcrumb-color;
  margin-bottom: 18px;
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  line-height: 16px;

  .icon{
    font-size: 16px;
  }

  > li {

    + li:before {
      color:  @breadcrumb-active-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}
