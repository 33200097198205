/*------------------------------------------------------------------
  [Splash screen]
*/
.be-splash-screen{
  background-color: @splash-screen-bg;

  .be-wrapper{
    padding-top: 0;

    .be-content{
      margin: 0;
    }
  }
}

//Centered container
.splash-container{
  max-width: @login-width;
  margin: @login-margin-top auto;

  .panel{
    margin-bottom: 30px;

    .panel-heading{
      text-align: center;
      margin-bottom: 20px;
      padding-top: 40px;
      padding-bottom: 0;
      background-color: #fff;
    }

    .panel-body{
      padding: 20px 30px 15px;

      .be-checkbox{
        display: inline-block;
        padding: 0;
      }
    }
  }

  @media @phone{
    margin-top: 50px;
  }
}

//Title
.splash-title{
  text-align: center;
  display: block;
  .font-size-large(2px);
  font-weight: @font-weight-light;
}

//Description text
.splash-description{
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: @font-size-base;
  color: lighten(@text-color, 10%);
  margin-top: 11px;
  padding-bottom: 10px;
}

//Footer
.splash-footer{
  text-align: center;
}
